import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./layout.css";
import findIndex from "lodash.findindex";
import { TweenMax } from "gsap/TweenMax";
import Loadable from "react-loadable";
import { createGlobalStyle } from "styled-components";

import { ScrollHintProvider } from "src/contexts/scrollHintContext";
import { NavbarProvider } from "src/contexts/navbarContext";

import Navbar from "src/components/navbar.js";
import Topbar from "src/components/topbar.js";

import LoadingImg from "src/images/loading.gif";
import copy from "src/copy";

const GlobalStyle = createGlobalStyle`
  html, body {
    ${props => (props.isLoading ? "overflow: hidden" : null)};
    position: relative;
    height: 100%;
    z-index: -502;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
  background-color: black;
  overflow: hidden;

  img {
    width: 225px;
  }
`;

const LoadingContainer = styled.div`
  opacity: 0;
`;

const loader = () => <div></div>;

const LogoAnimationLoadable = Loadable({
  loader: () => import("src/components/logoAnimation"), // imports the component with the three.js and allows use of it safely
  loading: loader,
});

export default ({ children, location }) => {
  const [isUIHidden, setUIHidden] = useState(true);
  const [loadingValue, setLoadingValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    TweenMax.to("#logo", 2, { autoAlpha: 1 });
    TweenMax.to("#loaded", 0.5, { autoAlpha: 0 });
  });

  useEffect(() => {
    const loc = location.pathname.replace(new RegExp("/", "g"), "");
    const { pages } = copy;
    const isFound = findIndex(
      pages,
      name => loc === name.toLowerCase() || loc.includes("tenants")
    );

    if (isFound !== -1) {
      setUIHidden(false);
    } else {
      setUIHidden(true);
    }
  }, [location]);

  useEffect(() => {
    if (loadingValue === 100) {
      setIsLoading(false);
    }
  }, [loadingValue]);

  useEffect(() => {
    if (isLoading === false) {
      window.scrollTo(0, 1);
    }
  }, [isLoading]);

  return (
    <NavbarProvider>
      <GlobalStyle isLoading={isLoading} />
      <LoadingOverlay id={isLoading ? "loading" : "loaded"}>
        <LoadingContainer id="logo">
          <img src={LoadingImg} alt="loading gif" />
        </LoadingContainer>
      </LoadingOverlay>
      <ScrollHintProvider>
        <div>
          <Topbar hidden={isUIHidden} />
          <Navbar hidden={isUIHidden} location={location} />
          <LogoAnimationLoadable
            setLoadingValue={setLoadingValue}
            location={location}
          />
          {children}
        </div>
      </ScrollHintProvider>
    </NavbarProvider>
  );
};
