import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Logo from "../images/hxouselogo.png";
import Bird from "../images/social/bird.png";
import Camera from "../images/social/camera.png";
import { TweenMax, Power4 } from "gsap";

const TopBarContainer = styled.div`
  display: flex;
  color: white;
  padding: 20px 25px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 69420;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 30%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 70%,
    rgba(0, 0, 0, 0.85) 100%
  );
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkSocial = styled.a`
  text-decoration: none;
  pointer-events: auto;
`;

const Img = styled.img`
  fill: white;
  width: 25px;
  pointer-events: auto;
  @media (max-width: 500px) {
    width: 20px;
  }
  @media (max-width: 330px) {
    width: 15px;
  }
`;

const CameraImg = styled(Img)`
  margin: 0 15px;
  pointer-events: auto;
`;

const LogoImg = styled.img`
  width: 35px;
  pointer-events: auto;
  @media (max-width: 500px) {
    width: 30px;
  }
  @media (max-width: 330px) {
    width: 25px;
  }
`;

const CTAButton = styled.a`
  pointer-events: auto;
  color: white;
  font-size: 14px;
  font-family: "URWAccidalia", sans-serif;
  background-color: black;
  border: 1px solid white;
  padding: 6px 6px 4px 6px;
  margin: 0;
  transition: background-color 0.5s ease, color 0.5s ease;
  @media (max-width: 500px) {
    font-size: 12px;
    padding: 5px 5px 3px 5px;
  }
  @media (max-width: 330px) {
    font-size: 10px;
    padding: 4px 4px 2px 4px;
  }
  &:hover {
    background-color: white;
    color: black;
  }
`;

const Topbar = ({ hidden, setRewindLogo }) => {
  //animation to show topbar moving in from top of page
  useEffect(() => {
    if (hidden) {
      TweenMax.to("#topbarContainer", 1.25, {
        y: -150,
        ease: Power4.easeInOut,
      });
      TweenMax.to("#topbarContainer", 1.25, {
        autoAlpha: 0,
        ease: Power4.easeInOut,
      });
    } else {
      TweenMax.to("#topbarContainer", 1.25, { y: 0, ease: Power4.easeInOut });
      TweenMax.to("#topbarContainer", 1.25, {
        autoAlpha: 1,
        ease: Power4.easeInOut,
      });
    }
  }, [hidden]);

  return (
    <TopBarContainer id="topbarContainer">
      <Link to="/">
        <LogoImg src={Logo} alt="HXOUSE logo" />
      </Link>
      <SocialContainer>
        <LinkSocial
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/HXOUSE"
        >
          <CameraImg src={Camera} alt="camera" />
        </LinkSocial>
        <LinkSocial
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/HXOUSE"
        >
          <Img src={Bird} alt="bird" />
        </LinkSocial>
      </SocialContainer>
    </TopBarContainer>
  );
};
export default Topbar;
