import React, { useEffect, useState, useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import useWindowSize from "../hooks/useWindowSize";
import styled from "styled-components";
import { TweenMax, TimelineMax, Power4 } from "gsap/TweenMax";
import { NavbarContext } from "src/contexts/navbarContext";

import Image1 from "src/images/bar/1.png";
import Image2 from "src/images/bar/2.png";
import Image3 from "src/images/bar/3.png";
import Image4 from "src/images/bar/4.png";
import Image5 from "src/images/bar/5.png";
import Image1Hover from "src/images/bar/1HOVER.png";
import Image2Hover from "src/images/bar/2HOVER.png";
import Image3Hover from "src/images/bar/3HOVER.png";
import Image4Hover from "src/images/bar/4HOVER.png";
import Image5Hover from "src/images/bar/5HOVER.png";
import copy from "src/copy";

const BREAKPOINT = 815;

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  z-index: 69420;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.7) 30%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: 500px) {
    padding-bottom: 15px;
  }

  @media (max-height: 500px) {
    padding-bottom: 5px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
`;

const SubheadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 80px;
  top: 15px;
`;

const SubheadingWrapper = styled.div`
  opacity: 0;
  color: #828282;
`;

const Heading = styled.h1`
  font-family: "URWAccidalia", sans-serif;
  text-transform: uppercase;
  color: white;
  margin: 15px 15px 0 15px;
  font-size: 10px;
  @media (max-width: 815px) {
    margin: 15px 8px 0 8px;
    font-size: 7px;
  }
`;

const Subheading = styled(Heading)`
  color: inherit;
`;

const ImageContainer = styled.div`
  height: 62px;
  width: 20px;
  position: relative;
  @media (max-width: 500px) {
    height: 44px;
    width: 14px;
  }

  @media (max-height: 500px) {
    height: 34px;
    width: 10px;
  }
`;

const Image = styled.img`
  height: 62px;
  width: 20px;
  position: absolute;
  z-index: 10;
  @media (max-width: 500px) {
    height: 44px;
    width: 14px;
  }

  @media (max-height: 500px) {
    height: 34px;
    width: 10px;
  }
`;

const ImageHover = styled.img`
  height: 62px;
  width: 20px;
  position: absolute;
  z-index: 5;
  @media (max-width: 500px) {
    height: 44px;
    width: 14px;
  }

  @media (max-height: 500px) {
    height: 34px;
    width: 10px;
  }
`;

const ClickableArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Navbar = ({ hidden, location }) => {
  //functions and states from context connected to HXOUSE
  const {
    jumpToVision,
    jumpToStory,
    jumpToTeam,
    jumpToGuests,
    subheading,
    isAnimating,
    animationComplete,
  } = useContext(NavbarContext);

  //width of screen hook
  const { width } = useWindowSize();

  //states for heading and subheading timelines
  const [raised, setRaised] = useState();
  const [isHomeOpen, setHomeOpen] = useState(false);
  const [homeTimeline, setHomeTimeline] = useState(new TimelineMax());

  //if not animating between hxouse navbar sections, raise subheadings when subheading state changes
  useEffect(() => {
    if (isAnimating === false && animationComplete === true) {
      raiseSubHeading(subheading, false);
    }
  }, [subheading]);

  //Setup hxouse timeline
  useEffect(() => {
    setHomeTimeline(
      homeTimeline
        .staggerFromTo(
          ["#guests", "#team", "#story", "#vision"],
          0.5,
          {},
          { autoAlpha: 1 },
          0.15,
          0.5
        )
        .to("#hxouse", 1, { x: -120, ease: Power4.easeInOut }, "0")
        .to("#programs", 1, { x: 120, ease: Power4.easeInOut }, "0")
        .to("#events", 1, { x: 120, ease: Power4.easeInOut }, "0")
        .to("#tenants", 1, { x: 120, ease: Power4.easeInOut }, "0")
        .to("#contact", 1, { x: 120, ease: Power4.easeInOut }, "0")
        .reverse()
    );
  }, [homeTimeline]);

  //if on small screen, turn off subheadings on nav
  useEffect(() => {
    if (width < BREAKPOINT) {
      if (isHomeOpen) {
        setHomeOpen(false);
      }
    }
  }, [width]);

  //reverse hometimeline when state changes
  useEffect(() => {
    homeTimeline.reversed(!isHomeOpen);
  }, [isHomeOpen, homeTimeline]);

  //Open up hxouse section of navbar when you get on hxouse page
  useEffect(() => {
    const loc = location.pathname
      .replace(new RegExp("/", "g"), "")
      .toLowerCase();
    if (loc === copy.pages[0].toLowerCase()) {
      expandHxouse();
    } else {
      expandOther();
      jumpToVision();
    }
  }, [location]);

  //function to expand hxouse section of navbar
  const expandHxouse = () => {
    if (width > BREAKPOINT) {
      if (!isHomeOpen) {
        setHomeOpen(!isHomeOpen);
        raised &&
          TweenMax.to(`#${raised}`, 0.5, {
            color: "#828282",
            y: 0,
            ease: Power4.easeInOut,
          });
        raiseSubHeading("vision", false);
      }
    }
  };

  //function to close hxouse section of navbar
  const expandOther = () => {
    if (width > BREAKPOINT) {
      if (isHomeOpen) {
        setHomeOpen(false);
      }
      raised &&
        TweenMax.to(`#${raised}`, 0.5, {
          color: "#828282",
          y: 0,
          ease: Power4.easeInOut,
        });
      setRaised("");
    }
  };

  //function to raise individual subheadings on navbar
  const raiseSubHeading = (sub, clicked) => {
    if (raised !== sub) {
      raised &&
        TweenMax.to(`#${raised}`, 0.5, {
          color: "#828282",
          y: 0,
          ease: Power4.easeInOut,
        });
      TweenMax.to(`#${sub}`, 0.5, {
        color: "white",
        y: -10,
        ease: Power4.easeInOut,
      });
      setRaised(sub);
    }

    //functions in context that will scroll hxouse page to different sections of navbar
    if (clicked === true) {
      if (sub === "vision") {
        jumpToVision();
      } else if (sub === "story") {
        jumpToStory();
      } else if (sub === "team") {
        jumpToTeam();
      } else if (sub === "guests") {
        jumpToGuests();
      }
    }
  };

  const raiseVision = () => {
    raiseSubHeading("vision", true);
  };

  const raiseStory = () => {
    raiseSubHeading("story", true);
  };

  const raiseTeam = () => {
    raiseSubHeading("team", true);
  };

  const raiseGuests = () => {
    raiseSubHeading("guests", true);
  };

  //animations to make navbar pop out from bottom
  useEffect(() => {
    if (hidden) {
      TweenMax.staggerTo(
        ["#hxouse", "#programs", "#events", "#tenants", "#contact"],
        1.25,
        { y: 150, autoAlpha: 0, ease: Power4.easeInOut },
        0
      );
    } else {
      TweenMax.staggerTo(
        ["#hxouse", "#programs", "#events", "#tenants", "#contact"],
        1.5,
        { y: 0, ease: Power4.easeInOut },
        0
      );
      TweenMax.staggerTo(
        ["#hxouse", "#programs", "#events", "#tenants", "#contact"],
        1.5,
        { autoAlpha: 1, ease: Power4.easeInOut },
        0
      );
    }
  }, [hidden]);

  const fadeOutRing = num => {
    TweenMax.to(`#navRing${num}`, 0.5, { autoAlpha: 0 });
  };

  const fadeInRing = num => {
    TweenMax.to(`#navRing${num}`, 0.5, { autoAlpha: 1 });
  };

  const hoverSubheadingVision = () => {
    if (raised !== "vision") {
      TweenMax.to("#vision", 0.5, { color: "#fff" });
    }
  };

  const hoverSubheadingVisionReverse = () => {
    if (raised !== "vision") {
      TweenMax.to("#vision", 0.5, { color: "#828282" });
    }
  };

  const hoverSubheadingStory = () => {
    if (raised !== "story") {
      TweenMax.to("#story", 0.5, { color: "#fff" });
    }
  };

  const hoverSubheadingStoryReverse = () => {
    if (raised !== "story") {
      TweenMax.to("#story", 0.5, { color: "#828282" });
    }
  };

  const hoverSubheadingEdu = () => {
    if (raised !== "guests") {
      TweenMax.to("#guests", 0.5, { color: "#fff" });
    }
  };

  const hoverSubheadingEduReverse = () => {
    if (raised !== "guests") {
      TweenMax.to("#guests", 0.5, { color: "#828282" });
    }
  };

  return (
    <Container id="container">
      <SectionWrapper id="hxouse">
        <AniLink fade to="/hxouse/">
          <ClickableArea
            onClick={expandHxouse}
            onMouseEnter={() => {
              fadeOutRing(1);
            }}
            onMouseLeave={() => {
              fadeInRing(1);
            }}
          >
            <ImageContainer>
              <Image
                id="navRing1"
                src={Image1}
                alt="home ring of the hxouse logo"
              />
              <ImageHover src={Image1Hover} />
            </ImageContainer>
            <Heading width={width}>HXOUSE</Heading>
          </ClickableArea>
        </AniLink>

        <SubheadingContainer>
          <SubheadingWrapper
            id="vision"
            onClick={raiseVision}
            onMouseEnter={hoverSubheadingVision}
            onMouseLeave={hoverSubheadingVisionReverse}
          >
            <Subheading width={width}>VISION</Subheading>
          </SubheadingWrapper>

          <SubheadingWrapper
            id="story"
            onClick={raiseStory}
            onMouseEnter={hoverSubheadingStory}
            onMouseLeave={hoverSubheadingStoryReverse}
          >
            <Subheading width={width}>STORY</Subheading>
          </SubheadingWrapper>

          {/* <SubheadingWrapper id="team" onClick={raiseTeam}>
            <Subheading width={width}>TEAM</Subheading>
          </SubheadingWrapper> */}

          <SubheadingWrapper
            id="guests"
            onClick={raiseGuests}
            onMouseEnter={hoverSubheadingEdu}
            onMouseLeave={hoverSubheadingEduReverse}
          >
            <Subheading width={width}>EDUCATORS</Subheading>
          </SubheadingWrapper>
        </SubheadingContainer>
      </SectionWrapper>

      <SectionWrapper id="programs">
        <AniLink fade to="/programs/">
          <ClickableArea
            onClick={expandOther}
            onMouseEnter={() => {
              fadeOutRing(2);
            }}
            onMouseLeave={() => {
              fadeInRing(2);
            }}
          >
            <ImageContainer>
              <Image
                id="navRing2"
                src={Image2}
                alt="program ring of the hxouse logo"
              />
              <ImageHover src={Image2Hover} />
            </ImageContainer>
            <Heading width={width}>PROGRAMS</Heading>
          </ClickableArea>
        </AniLink>
      </SectionWrapper>

      <SectionWrapper id="events">
        <AniLink fade to="/events/">
          <ClickableArea
            onClick={expandOther}
            onMouseEnter={() => {
              fadeOutRing(3);
            }}
            onMouseLeave={() => {
              fadeInRing(3);
            }}
          >
            <ImageContainer>
              <Image
                id="navRing3"
                src={Image3}
                alt="event ring of the hxouse logo"
              />
              <ImageHover src={Image3Hover} />
            </ImageContainer>
            <Heading width={width}>EVENTS</Heading>
          </ClickableArea>
        </AniLink>
      </SectionWrapper>

      <SectionWrapper id="tenants">
        <AniLink fade to="/tenants/">
          <ClickableArea
            onClick={expandOther}
            onMouseEnter={() => {
              fadeOutRing(4);
            }}
            onMouseLeave={() => {
              fadeInRing(4);
            }}
          >
            <ImageContainer>
              <Image
                id="navRing4"
                src={Image4}
                alt="tenant ring of the hxouse logo"
              />
              <ImageHover src={Image4Hover} />
            </ImageContainer>
            <Heading width={width}>TENANTS</Heading>
          </ClickableArea>
        </AniLink>
      </SectionWrapper>

      <SectionWrapper id="contact">
        <AniLink fade to="/contact/">
          <ClickableArea
            onClick={expandOther}
            onMouseEnter={() => {
              fadeOutRing(5);
            }}
            onMouseLeave={() => {
              fadeInRing(5);
            }}
          >
            <ImageContainer>
              <Image
                id="navRing5"
                src={Image5}
                alt="contact ring of the hxouse logo"
              />
              <ImageHover src={Image5Hover} />
            </ImageContainer>
            <Heading width={width}>CONTACT</Heading>
          </ClickableArea>
        </AniLink>
      </SectionWrapper>
    </Container>
  );
};

export default Navbar;
