import BoiImg from "src/images/guests/boi.jpg";
import ChrisImg from "src/images/guests/chris.jpg";
import DanielImg from "src/images/guests/daniel.jpg";
import DoctorWooImg from "src/images/guests/doctorwoo.jpg";
import EbonieImg from "src/images/guests/ebonie.jpg";
import ErrolsonImg from "src/images/guests/errolson.jpg";
import EsImg from "src/images/guests/es.jpg";
import HalseyImg from "src/images/guests/halsey.jpg";
import SydneyImg from "src/images/guests/sydney.jpg";
import JenniferImg from "src/images/guests/jennifer.jpg";
import LalaImg from "src/images/guests/lala.jpg";
import LolaImg from "src/images/guests/lola.jpg";
import MatthewImg from "src/images/guests/matthew.jpg";
import MetroImg from "src/images/guests/metro.jpg";
import MixedImg from "src/images/guests/mixed.jpg";
import MorganImg from "src/images/guests/morgan.jpg";
import MustafaImg from "src/images/guests/mustafa.jpg";
import NabilImg from "src/images/guests/nabil.jpg";
import OlivaImg from "src/images/guests/oliva.jpg";
import OpaImg from "src/images/guests/opa.jpg";
import SadfieImg from "src/images/guests/sadfie.jpg";
import SwizzImg from "src/images/guests/swizz.jpg";
import WhiteTrashTylerImg from "src/images/guests/whitetrashtyler.jpg";
import WondagurlImg from "src/images/guests/wondagurl.jpg";

import Angelica from "src/images/tenants/angelica.jpg";
import Bianca from "src/images/tenants/bianca.jpg";
import Trish from "src/images/tenants/trish.jpg";
import Joey from "src/images/tenants/joey.jpg";
import Rahul from "src/images/tenants/rahul.jpg";
import Teiji from "src/images/tenants/teiji.jpg";
import Hallie from "src/images/tenants/hallie.jpg";
import Arthikka from "src/images/tenants/arthikka.jpg";
import Flora from "src/images/tenants/flora.jpg";
import Erika from "src/images/tenants/erika.jpg";
import Max from "src/images/tenants/max.jpg";
import Saint from "src/images/tenants/saint.jpg";
import Mimi from "src/images/tenants/mimi.jpg";
import Michelle from "src/images/tenants/michelle.jpg";
import Andi from "src/images/tenants/andi.jpg";
import Andrew from "src/images/tenants/andrew.jpg";
import Jeff from "src/images/tenants/jeff.jpg";
import Zakiyah from "src/images/tenants/zakiyah.jpg";

import Challenges from "src/images/programs/challenges.jpg";
import NoMoreDreams from "src/images/programs/nomoredreams.jpg";
import Panels from "src/images/programs/panels.jpg";
import Workshops from "src/images/programs/workshops.jpg";
import ErrolsonWorkshop from "src/images/pastevents/errolsonWorkshop/errolsonworkshop.jpg";
import HxouseLive from "src/images/pastevents/hxouseLive/hxouselive.jpg";
import OpenHxouse from "src/images/pastevents/openHxouse/openhxouse.jpg";
import FemaleEntrepreneurship from "src/images/pastevents/femaleEntrepreneurship/femaleentrepreneurship.jpg";
import EngineEars from "src/images/pastevents/engineEars/engineears.jpg";
import StrangeLoops from "src/images/pastevents/strangeLoops/strangeloops.jpg";
import MetroBoomin from "src/images/pastevents/metroBoomin/metroboomin.jpg";
import LaLa from "src/images/pastevents/laLa/lala.jpg";
import Rhuigi from "src/images/pastevents/rhuigi/rhuigi.jpg";
import Safdie from "src/images/pastevents/safdie/safdie.jpg";

import MicrosoftLogo from "src/images/sponsors/microsoft.png";

const copy = {
  pages: ["HXOUSE", "PROGRAMS", "EVENTS", "TENANTS", "CONTACT"],
  // MAKE SURE THIS IS IN ALPHABETICAL ORDER, TIGHTLY COUPLED WITH THEIR WORK
  tenants: [
    {
      name: "ANDI",
      title: "Graphic Designer",
      description:
        "Andi Bordt is a 22-year-old designer and multidisciplinary artist. He is pursuing his Bachelor of Design at OCAD University and maintaining a Designer position at Public Address Studio. As of late, his focus and specialty is brand architecture, visual identity, and brand campaigns.",
      ig: "abordt",
      website: "",
      image: Andi,
      imageRef: "andiBordt",
    },
    {
      name: "ANDREW",
      title: "Product Designer",
      description:
        "Drew Hall is a product designer with a background in fashion. Utilizing design and strategy to create well-rounded experiences for brands and products.",
      ig: "offbranddrew",
      website: "",
      image: Andrew,
      imageRef: "andrewHall",
    },
    {
      name: "ANGELICA",
      title: "Director",
      description:
        "ANGELICAMILASH is an international director and filmmaker. Ever since she moved to Toronto at the age of 14 and got her start as a production assistant, her goal stayed the same - make people experience more of life through every project she touches.",
      ig: "angelicamilash",
      website: "WWW.ANGELICAMILASH.COM",
      image: Angelica,
      imageRef: "angelicaMilash",
    },
    {
      name: "ARTHIKKA",
      title: "Marketing Consultant",
      description:
        "Arthikka Jey is a multidisciplinary consultant & designer based in Toronto. Between creative strategy & design, she blurs the line. With over five years of experience in the field, she is running her own digital marketing agency that specializes in branding, content & social media",
      ig: "arxka",
      website: "",
      image: Arthikka,
      imageRef: "arthikkaJey",
    },
    {
      name: "BIANCA",
      title: "Strategist/Entrepreneur",
      description:
        "Bianca Li Channer is a design + brand strategist and entrepreneur, focusing on music industry-adjacent startups. Exploring intersections of speculative design, business and experimentation as a creative process, her work has been recognized by girlboss, ELLE Canada and CBC. Bianca was featured in Complex Media's Good Looking Out web series for millennial entrepreneurs, hosted by Karen Civil.",
      ig: "biancalichanner",
      website: "WWW.BIANCALICHANNER.COM",
      image: Bianca,
      imageRef: "biancaLichanner",
    },
    {
      name: "ERIKA",
      title: "Fashion Designer",
      description:
        "Erika War moved from rural Alberta to Toronto in her teens to pursue a career in the creative industry. Her work has bounced between the fashion, tech, and music industries while specializing in photography and digital media. In 2019, she launched a recycling design studio MADE by WAR, and subsequently, a collection of gender-neutral pop-art inspired clothing.",
      ig: "erikawar",
      website: "WWW.ERIKAWAR.COM",
      image: Erika,
      imageRef: "erikaWar",
    },
    {
      name: "FLORA",
      title: "Graphic Designer",
      description:
        "Flora Yin is a 22-year-old freelance production + graphic designer with an education background in architecture from the University of Toronto. As of late, her focus has been within the Toronto music industry, working with local artists: anders / NST and Sean Leon.",
      ig: "flryn_",
      website: "",
      image: Flora,
      imageRef: "floraYin",
    },
    {
      name: "HALLIE",
      title: "Creative Director",
      description:
        "Hallie Tut is a 19 year old hopeless romantic obsessed with the idea of feeling like a teenager forever. Seeing no barriers between mediums, she plays with colour and light to create worlds to escape from her own.",
      ig: "Hallietut",
      website: "WWW.HALLIETUT.COM",
      image: Hallie,
      imageRef: "hallieTutkaluk",
    },
    {
      name: "JEFF",
      title: "Graphic Designer",
      description:
        "Jeff Feltham is a 20 year old artist and graphic designer from Toronto. His many released works are a diverse collection of both graphic art, from posters and personal projects to his many pieces as a commissioned artist. Jeff believes he is an ideas person at his core, and an artist before a graphic designer. He loves to tell a story, and get his messages across through his various outputs of expression.",
      ig: "jefffeltham",
      website: "",
      image: Jeff,
      imageRef: "jeffFeltham",
    },
    {
      name: "JOEY",
      title: "Creative Director",
      description:
        "The Mr. @ Mr. Saturday, a luxury brand sold worldwide at luxury boutiques and shown at Paris Fashion Week. His work has been featured on media outlets, like Highsnobiety, and worn by the likes of J. Cole and Grace Jones. He’s passionate about sustainability, meditation, and education.",
      ig: "heymrsaturday",
      website: "WWW.HEYMRSATURDAY.COM",
      image: Joey,
      imageRef: "joeyGollish",
    },
    {
      name: "MAXIM",
      title: "Fashion Designer",
      description:
        "Maxim is a fashion designer who thinks the future is old news. His upcoming line MAISON AMADÉ will be an experiment in brand identity; toying with the fluidity of a fashion house’s story to create a future we always longed for.",
      ig: "notnotmax",
      website: "",
      image: Max,
      imageRef: "maxGottlieb",
    },
    {
      name: "MICHELLE",
      title: "Creative Strategist",
      description:
        "Michelle is a Creative Strategist specializing in the fashion and beauty industry, having worked with brands that drive youth culture such as MAC Cosmetics and Nike.",
      ig: "beautymilk_ ",
      website: "",
      image: Michelle,
      imageRef: "michelleBali",
    },
    {
      name: "MIMI",
      title: "Director/Designer",
      description:
        "Mimi Vuong is a Director and Designer who was born in Vietnam but raised in Canada. Currently she runs her own creative practice that primarily specializes in Film and Motion Design. She works with clients such as Canon, Hypebeast, Rap Season, and more in the tech, music, and entertainment industries.",
      ig: "misquared",
      website: "WWW.MIMIVUONG.COM",
      image: Mimi,
      imageRef: "mimiVuong",
    },
    {
      name: "RAHUL",
      title: "Creative Director",
      description:
        "Rahul Madan is the co-founder of contemporary menswear label ‘WIL Studios’, offering a progressive take on everyday wear, and sister agency ‘WHAT I LIKE’, providing creative strategy, direction and production of campaigns, identities and experiences.",
      ig: "madanrahul",
      website: "WWW.WILSTUDIOS.COM",
      image: Rahul,
      imageRef: "rahulMadan",
    },
    {
      name: "SAINT",
      title: "Fashion Designer",
      description:
        "Saint. Influenced by urban culture, his personal life journey, and the music he grew up on. His design aesthetic is a direct embodiment of himself. Designing only garments he would himself wear or like to see in the world. Heavily inspired by listening and looking up to rappers like ASAP Rocky, Kanye West, Kid Cudi, he believes the clothes people wear should give them the same confidence as the rappers he used to see on stage.",
      ig: "svainty",
      website: "",
      image: Saint,
      imageRef: "saintAmorah",
    },
    {
      name: "TEIJI",
      title: "Artist",
      description:
        "Elliot Teiji Reid is a multidisciplinary street artist blurring the lines between vandalism and fine art. Inspired by the graffiti writers and muralists before him he is fascinated with the concept of transforming public space through creative expression.",
      ig: "Elliot.reid",
      website: "",
      image: Teiji,
      imageRef: "teijiReid",
    },
    {
      name: "TRISH",
      title: "Artist/Designer",
      description:
        "Trish is a multidisciplinary creative fascinated with the influence of designed environment on human physiological response. With a focus in design and curating immersive spaces—past work involves blurring the lines of digital and physical using VR and projection mapping technology. Trish was the recipient of a German Design Award Special in 2019 for furniture design.",
      ig: "roquesque",
      website: "",
      image: Trish,
      imageRef: "patriciaRoque",
    },
    {
      name: "ZAKIYAH",
      title: "Creative Director",
      description:
        "Zakiyah works with artists and creators to cultivate their brand by providing design, photography, and business strategy. With a desire to teach others and discover opportunities, he is committed to putting power back into the hands of creators for generations to come.",
      ig: "lionpawvisual",
      website: "",
      image: Zakiyah,
      imageRef: "zakiyahWilliams",
    },
  ],
  programs: [
    {
      name: "INTERNAL",
      desc:
        "Internal Programming is reserved for individuals that have been accepted into our Tenancy program which is subsidized by our NO MORE DREAMS Scholarship fund. These members submit an application to our open call where they are evaluated by a judiciary committee and later accepted to participate in a variety of private programming sprints, workshops, and challenges.",
    },
    {
      name: "HYBRID",
      desc:
        "Individuals from the broader creative sector that demonstrate above-average creative ability or have existing ties to our partner institutions like Artscape, George Brown College and OCAD U are invited to participate in some of our “members only” workshops, challenges and panel discussions related to industry and professional development.",
    },
    {
      name: "EXTERNAL",
      desc:
        "These events are open to the public and are available on a first-come, first-served basis. Currently these public facing events comprise of panels led by industry leaders and networking events.",
    },
  ],
  programsOne: [
    {
      name: "NO MORE DREAMS",
      image: NoMoreDreams,
      gatsbyRef: "nomoredreams",
      desc:
        "The framework of the multi-level “No More Dreams” scholarship program can be broken down into three levels accessible only by acceptance by a judiciary committee at each new level. The curriculum of this program carries an adaptive nature whereby the model can be applied to sectors outside of the creative arts.",
    },
    {
      name: "NO MORE DREAMS",
      image: NoMoreDreams,
      gatsbyRef: "nomoredreams",
      desc:
        "The framework of the multi-level “No More Dreams” scholarship program can be broken down into three levels accessible only by acceptance by a judiciary committee at each new level. The curriculum of this program carries an adaptive nature whereby the model can be applied to sectors outside of the creative arts.",
    },
    {
      name: "NO MORE DREAMS",
      image: NoMoreDreams,
      gatsbyRef: "nomoredreams",
      desc:
        "The framework of the multi-level “No More Dreams” scholarship program can be broken down into three levels accessible only by acceptance by a judiciary committee at each new level. The curriculum of this program carries an adaptive nature whereby the model can be applied to sectors outside of the creative arts.",
    },
    {
      name: "NO MORE DREAMS",
      image: NoMoreDreams,
      gatsbyRef: "nomoredreams",
      desc:
        "The framework of the multi-level “No More Dreams” scholarship program can be broken down into three levels accessible only by acceptance by a judiciary committee at each new level. The curriculum of this program carries an adaptive nature whereby the model can be applied to sectors outside of the creative arts.",
    },
  ],
  programsTwo: [
    {
      name: "WORKSHOPS",
      image: Workshops,
      gatsbyRef: "workshops",
      desc:
        "HXOUSE hosts workshops to help improve skills of young creatives. Depending on the nature of the workshop, tenants and/or the general public are either selected or granted access based on meeting eligible criteria. These workshops are often led by leading industry experts, both local and international, to ensure learning in an engaging and reciprocal way.",
    },
    {
      name: "WORKSHOPS",
      image: Workshops,
      gatsbyRef: "workshops",
      desc:
        "HXOUSE hosts workshops to help improve skills of young creatives. Depending on the nature of the workshop, tenants and/or the general public are either selected or granted access based on meeting eligible criteria. These workshops are often led by leading industry experts, both local and international, to ensure learning in an engaging and reciprocal way.",
    },
    {
      name: "CHALLENGES",
      image: Challenges,
      gatsbyRef: "challenges",
      desc:
        "Throughout the year, HXOUSE hosts challenges open to Tenants part of the No More Dreams program. The challenges usually start with an open call and encourage the creation of new teams in Toronto’s creative community. This year’s challenges included a fashion challenge by Errolson Hugh and a city partnered challenge with Nuit Blanche.",
    },
    {
      name: "CHALLENGES",
      image: Challenges,
      gatsbyRef: "challenges",
      desc:
        "Throughout the year, HXOUSE hosts challenges open to Tenants part of the No More Dreams program. The challenges usually start with an open call and encourage the creation of new teams in Toronto’s creative community. This year’s challenges included a fashion challenge by Errolson Hugh and a city partnered challenge with Nuit Blanche.",
    },
  ],
  programsThree: [
    {
      name: "IN-HXOUSE CONVERSATIONS",
      image: Challenges,
      gatsbyRef: "conversations",
      desc:
        "IN-HXOUSE Conversations are panels and discussions that are a monthly activity offered to the public on a sign-up basis. The talks consist of entrepreneurs from various industries discussing topics such as their creative process, the future of art and tech, city building and what that looks like for the creative communities within them.",
    },
    {
      name: "IN-HXOUSE CONVERSATIONS",
      image: Challenges,
      gatsbyRef: "conversations",
      desc:
        "IN-HXOUSE Conversations are panels and discussions that are a monthly activity offered to the public on a sign-up basis. The talks consist of entrepreneurs from various industries discussing topics such as their creative process, the future of art and tech, city building and what that looks like for the creative communities within them.",
    },
    {
      name: "CONNECTIONS",
      image: NoMoreDreams,
      gatsbyRef: "connections",
      desc:
        "Connections, in partnership with Artscape Launchpad, are HXOUSE stapled events that facilitate networking between industry and allow creative youth to showcase and be paid for their work through the curation of installations. These events ensure that youth are able to make meaningful connections with their peers and industry professionals.",
    },
    {
      name: "CONNECTIONS",
      image: NoMoreDreams,
      gatsbyRef: "connections",
      desc:
        "Connections, in partnership with Artscape Launchpad, are HXOUSE stapled events that facilitate networking between industry and allow creative youth to showcase and be paid for their work through the curation of installations. These events ensure that youth are able to make meaningful connections with their peers and industry professionals.",
    },
  ],
  pastEvents: [
    {
      heading: "OPEN HXOUSE",
      subheading: "Event",
      description:
        "A two-day event dedicated to the opening of HXOUSE. Day 1 of the event encompassed a conversation with Nabil, Daniel Arsham, Matthew Williams, White Trash Tyler and Dr. Woo, on building their unique individual visual identity. Day two encompassed a conversation with Halsey, Cash, Boi-1da, OPN, Mustafa The Poet, Wondagurl and Swizz Beatz on breaking through the music industry.",
      image: OpenHxouse,
      gatsbyRef: "openhxouse",
    },
    {
      heading: "HXOUSE LIVE",
      subheading: "CONCERT",
      description:
        "OPEN HXOUSE culminated in a sold out, blockbuster concert featuring NAV, The Neighbourhood, Bryson Tiller, and The Weeknd.",
      image: HxouseLive,
      gatsbyRef: "hxouselive",
    },
    {
      heading: "EROLLSON HUGH",
      subheading: "IN-HXOUSE Conversation",
      description:
        "The founder of ACRONYM, Errolson Hugh presented an asymmetric lecture on the exploration of the fashion design process. Hugh explored how to take a nuanced approach to the design journey and learn the best ways to communicate not just your pieces but also your philosophical approach.",
      image: ErrolsonWorkshop,
      gatsbyRef: "errolsonworkshop",
      sponsoredLogo: MicrosoftLogo,
    },
    // {
    //   heading: "ES DEVLIN",
    //   subheading: "IN-HXOUSE Conversation",
    //   description:
    //     "Every Journey has its story. Es Devlin shared the importance of finding your process. How creating a story circle has been an instrumental tool in unlocking her creative process and how all artists can use this process to find an understanding of how to effectively share the intended mission of their art.",
    //   image: ErrolsonWorkshop,
    //   gatsbyRef: "errolsonworkshop",
    // },
    {
      heading: "FEMALE ENTREPRENEURSHIP",
      subheading: "IN-HXOUSE Conversation",
      description:
        "The IN-HXOUSE Conversation on female entrepreneurship brought together Bella Hadid, La La Anthony, Jennifer Rubio, Morgan DeBaun and Olivia Perez to share their insight with an audience of aspiring entrepreneurs on what it takes to establish your path with hard work, dedication and collaboration.",
      image: FemaleEntrepreneurship,
      gatsbyRef: "femaleentrepreneurship",
      sponsoredLogo: MicrosoftLogo,
    },
    {
      heading: "ENGINEEARS",
      subheading: "Workshop",
      description:
        "EngineEars, a masterclass workshop led by MixedbyAli started with a very ground level conversation on his trials and tribulations, creative process, overcoming failure and handling success in a vicious industry, followed by a technical deconstruction of one of his latest, notable mixes. Special thank you to our partners Artscape Launchpad and Noblegent.",
      image: EngineEars,
      gatsbyRef: "engineears",
    },
    {
      heading: "STRANGELOOP STUDIOS",
      subheading: "IN-HXOUSE Conversation",
      description:
        "IN-HXOUSE Conversations with Strangeloop Studios moderated by TOKiMONSTA presented a talk about process in chaos. SLS discussed deconstructing synesthesia, storytelling in digital art, and the future of immersive experiences. They brought some insight into the process of giving form to sound in the live space and finding the beauty in the chaotic.",
      image: StrangeLoops,
      gatsbyRef: "strangeloops",
      sponsoredLogo: MicrosoftLogo,
    },
    {
      heading: "METRO BOOMIN",
      subheading: "IN-HXOUSE Conversation",
      description:
        "At the IN-HXOUSE Conversation moderated by MyBestFriendJacob, power producer Metro Boomin discussed cementing your sonic identity and building a brand around your sound. Metro took us through his journey, sharing the obstacles he faced in his career and how he overcame them.",
      image: MetroBoomin,
      gatsbyRef: "metroboomin",
      sponsoredLogo: MicrosoftLogo,
    },
    {
      heading: "LA LA ANTHONY + EBONIE WARD",
      subheading: "IN-HXOUSE Conversation",
      description:
        "There is no one-size-fits-all definition of an entrepreneur. It is up to you to pave your own entrepreneurial path and learn from those who lead the charge. La La Anthony and Ebonie Ward took us through their own career journey and insights into the industries in which they dominate. Moderated by Lola Plaku.",
      image: LaLa,
      gatsbyRef: "lala",
      sponsoredLogo: MicrosoftLogo,
    },
    {
      heading: "RHUIGI",
      subheading: "IN-HXOUSE Conversation",
      description:
        "Failure is the foundation for success. Founder of Rhude, Rhuigi Villaseñor went all out to detail his own journey of a young hustler overcoming barriers that almost all immigrants have faced.⁣ In a conversation moderated by Othello Grey, he spoke on how to make it; your way, understating that  timing is everything, but still take the time.",
      image: Rhuigi,
      gatsbyRef: "rhuigi",
      sponsoredLogo: MicrosoftLogo,
    },
    {
      heading: "SAFDIE BROS + OPN",
      subheading: "IN-HXOUSE Conversation",
      description:
        'The Safdie Brothers along with Oneohtrix Point Never talked about what it\'s like to work collaboratively with each other to build a filmic world. In a conversation moderated by Jamie Webster, they shared the process of many of their previous films and even shared some insights on their latest film "Uncut Gems."',
      image: Safdie,
      gatsbyRef: "safdie",
      sponsoredLogo: MicrosoftLogo,
    },
  ],
  currentEvents: [
    {
      name: "NUIT TALKS -- CLOSING PANEL",
      date: "OCT 10",
      url:
        "https://www.eventbrite.ca/e/nuit-talks-closing-panel-tickets-75499911267",
    },
    {
      name: "HXOUSE YEAR ONE -- GRADUATION AFTER PARTY",
      date: "OCT 11",
      url:
        "https://www.eventbrite.com/e/hxouse-year-one-graduation-afterparty-tickets-75607286429",
    },
  ],
  // GUESTS FILE NAMES MUST BE IN ALPHABETICAL ORDER
  guests: [
    {
      name: "BOI-1DA",
      bio:
        "Toronto native Matthew Jehu Samuels, known professionally as Boi-1da, is an award winning record producer for a variety of artists and groups, most notably The Weeknd, Rihanna, Eminem, Jay-Z, Nicki Minaj, Nas, Lana Del Rey and Kendrick Lamar.",
      img: BoiImg,
    },
    {
      name: "CHRIS PANDOLFI",
      bio:
        "Chris is a designer and educator working on design strategy, spatial design and UX/UI projects. He is the managing partner and creative director of the Toronto and Copenhagen based design studio, Department of Unusual Certainties. Chris collaborates with the HXOUSE team on curriculum and programming.",
      img: ChrisImg,
    },
    {
      name: "DANIEL ARSHAM",
      bio:
        "Daniel is a New York based artist creating between art, architecture and performance. Arsham creates indefinable moments by staging what he refers to as “future relics of the present.” He has collaborated with the likes of Pharrell Williams and Adidas, among others.",
      img: DanielImg,
    },
    {
      name: "DOCTOR WOO",
      bio:
        "Tattoo artist to celebrities like Drake, Kendall Jenner, Harry Styles, Cara Delevingne and Chiara Ferragni, Dr. Woo is leading the movement where tattoos, art, and fashion become one and the same. Dr. Woo is continuing to progress his era defining tattoos, while building upon past collaboration and branding.",
      img: DoctorWooImg,
    },
    {
      name: "EBONIE WARD",
      bio:
        "Ebonie Ward, partner at Emagen Entertainment Group, is a leader who understands the importance of being on the ground working hard for clients to get the job done. Since 2017, she has handled management for all Freebandz artists as well as Gunna and producer Turbo.",
      img: EbonieImg,
    },
    {
      name: "ERROLSON HUGH",
      bio:
        "Alberta native, Errolson Hugh is a leading streetwear designer and co-founder of a Berlin-based brand ACRONYM. Hugh’s original style has made him sought out by massive streetwear brands such as Burton, Stone Island’s Shadow Project, UNITED ARROWS, and Herno Laminar.",
      img: ErrolsonImg,
    },
    {
      name: "ES DEVLIN",
      bio:
        "Es Devlin is a London-based artist and stage designer, best known for creating large-scale performative sculptures and environments that fuse music, language and light. Es’ portfolio includes collaborations with Beyoncé, The Weeknd, Adele, U2, Kanye West, Miley Cirus and the Royal Opera House in London.",
      img: EsImg,
    },
    {
      name: "HALSEY",
      bio:
        "Halsey also known as Ashley Nicolette Frangipane, is an American pop artist and songwriter. Having been nominated for several awards, Halsey won the Rising Star award by Billboard Women in Music in 2016, which is awarded to women in the music industry who have not only made a significant contribution but also have inspired others.",
      img: HalseyImg,
    },
    {
      name: "JENNIFER RUBIO",
      bio:
        "Jennifer Rubio is an American businesswoman. She is the co-founder, President, and Chief Brand Officer of Away, a direct-to-consumer lifestyle brand that creates luggage and other travel products.",
      img: JenniferImg,
    },
    {
      name: "LA LA ANTHONY",
      bio:
        'Alani Nicole "La La" Anthony is an American television personality, New York Times best-selling author, businesswoman, producer and actress known for her roles in Think Like a Man and Starz original drama series Power.',
      img: LalaImg,
    },
    {
      name: "LOLA PLAKU",
      bio:
        "Formerly a music journalist, Slovakia-born Toronto native has made a name for herself by breaking U.S talent in the Toronto market. Lola now runs a mentorship program called GIRL CONNECTED and her own New-York based company, Lola Media Group which specializes in artist marketing, brand development and management.",
      img: LolaImg,
    },
    {
      name: "MATTHEW WILLIAMS",
      bio:
        "Founder of 1017 ALYX 9SM, Williams’ designs break the line between luxe streetwear, high fashion, and fetish-esque leatherwear with aplomb. Born in Chicago, raised in Pismo Beach, California, Matthew now lives in Ferrara, Italy where he is innovating his designs and solidifying his place on the global fashion scene.",
      img: MatthewImg,
    },
    {
      name: "METRO BOOMIN",
      bio:
        "Metro Boomin is an American record producer, record executive, songwriter, and DJ. Metro produced for some of today’s biggest names in music like Travis Scott, Future, and The Weeknd.",
      img: MetroImg,
    },
    {
      name: "MIXEDBYALI",
      bio:
        "Derek Ali popularly known as MixedbyAli is a Grammy Award-winning mix engineer. Aside from working with an incredible roster of artists including Kendrick Lamar, Jay Rock, SZA, Bas, Nipsey Hussle, and Snoop Dogg, Ali invented an online mixing program and community for mixing engineers called EngineEars.",
      img: MixedImg,
    },
    {
      name: "MORGAN DEBAUN",
      bio:
        "Founder & CEO of Blavity, Morgan started her career in Silicon Valley. She is a serial startup entrepreneur; whose passions include building technology at the intersection of creativity and culture. Since its launch in 2014, Morgan has scaled Blavity to be one the fastest growing digital media startups, currently operating five brands.",
      img: MorganImg,
    },
    {
      name: "MUSTAFA THE POET",
      bio:
        "Mustafa Ahmed is a game changing poet, singer, and songwriter from Toronto. Mustafa has worked with power houses like Valentino and artists including Drake, the Weeknd, Daniel Caesar and Camila Cabello.",
      img: MustafaImg,
    },
    {
      name: "NABIL",
      bio:
        "Chicago born, Australia raised creative. Nabil’s realm of work covers advertising to magazine editorials, artist branding, books, music videos and film. He’s worked with artists such as Kanye West, Frank Ocean, and Bon Iver, and has created campaigns for Bud Light and Nike.",
      img: NabilImg,
    },
    {
      name: "OLIVIA PEREZ",
      bio:
        "Olivia Perez is an influencer, businesswoman, and entrepreneur in New York City. She is the founder and Editor-in-chief of Friend of a Friend, a lifestyle media.",
      img: OlivaImg,
    },
    {
      name: "ONEOHTRIX POINT NEVER",
      bio:
        "Daniel Lopatin, best known by the recording alias Oneohtrix Point Never, is a Brooklyn-based American composer, producer and singer-songwriter of experimental and electronic music. His work in the film Good Time has won him the Soundtrack Award at the 2017 Cannes Film Festival.",
      img: OpaImg,
    },
    {
      name: "SAFDIE BROTHERS",
      bio:
        "Raised in New York, Josh and Benny Safdie began making movies at a young age, inspired by their film-enthusiast father, Alberto. At Boston University, they co-founded, with Alex Kalman, the creative collective Red Bucket Films. Their filmography includes Heaven Knows What, Good Time, and Uncut Gems.",
      img: SadfieImg,
    },
    {
      name: "SWIZZ BEATZ",
      bio:
        "Kasseem Dean, known professionally as Swizz Beatz, is an American hip hop recording artist, DJ, record producer, art collector, and entrepreneur from New York City. Swizz Beats has worked with a vast roster of stars, including Whitney Houston, Beyoncé and Lil Wayne.",
      img: SwizzImg,
    },
    {
      name: "SYDNEY ALLEN-ASH",
      bio:
        "Sydney Allen-Ash is a Canadian strategist, moderator and podcast host based in New York focusing on addressing discrimination and oppression across the creative industries. With a professional background in fashion, advertising, communications and organizational culture she weaves nuanced interdisciplinary thinking into programs and critical conversations that drive real change.",
      img: SydneyImg,
    },
    {
      name: "WHITE TRASH TYLER",
      bio:
        "Since entering the scene in 2013, the Canadian, self-taught director-editor has added the likes of Kim and Kanye, Travis Scott, Drake, 21 Savage, Tyga, Chief Keef, FKA Twigs, Diplo and A$AP Rocky to his curriculum vitae.",
      img: WhiteTrashTylerImg,
    },
    {
      name: "WONDAGURL",
      bio:
        "Ebony Naomi Oshunrinde, known as WondaGurl, is a Canadian record producer. WondaGurl has worked with prominent artists in the music industry, including Lil Uzi Vert, Big Sean, Travis Scott, Jay Z, Young Thug and Kanye West.",
      img: WondagurlImg,
    },
  ],
};

export default copy;
