import React, { createContext, useState } from "react";
import { TweenMax, TimelineMax, Power4 } from "gsap/TweenMax";

export const NavbarContext = createContext();

export function NavbarProvider(props) {
  //states to track navbar sub headings for hxouse page
  const [jumpValue, setJumpValue] = useState(0);
  const [subheading, setSubheading] = useState("vision");
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isClicked, setIsClicked] = useState(0);

  //function to change state values
  const changeAnimationComplete = value => {
    setAnimationComplete(value);
  };
  const changeIsAnimatingTrue = () => {
    setIsAnimating(true);
  };
  const changeIsAnimatingFalse = () => {
    setIsAnimating(false);
  };
  const changeSubheading = subheading => {
    setSubheading(subheading);
  };
  const addClick = () => {
    setIsClicked(prev => prev + 1);
  };
  const jumpToVision = () => {
    addClick();
    setJumpValue(0);
  };
  const jumpToStory = () => {
    addClick();
    setJumpValue(0.23);
  };
  const jumpToTeam = () => {
    addClick();
    setJumpValue(0.43);
  };
  const jumpToGuests = () => {
    addClick();
    setJumpValue(0.58);
  };
  return (
    <NavbarContext.Provider
      value={{
        jumpValue,
        jumpToGuests,
        jumpToStory,
        jumpToTeam,
        jumpToVision,
        changeSubheading,
        subheading,
        isAnimating,
        changeIsAnimatingTrue,
        changeIsAnimatingFalse,
        isClicked,
        animationComplete,
        changeAnimationComplete,
      }}
    >
      {props.children}
    </NavbarContext.Provider>
  );
}
