import React, { createContext, useState } from "react";

export const ScrollHintContext = createContext();

export function ScrollHintProvider(props) {
  //state to check if landing animation has been interacted with yet
  const [hasAnimated, setHasAnimated] = useState(false);
  const changeHasAnimated = () => {
    setHasAnimated(true);
  };
  return (
    <ScrollHintContext.Provider
      value={{
        hasAnimated,
        changeHasAnimated,
      }}
    >
      {props.children}
    </ScrollHintContext.Provider>
  );
}
